import {Pages} from "./Pages";
import React from "react";
import ReactDOM     from "react-dom";
import {WishesForm} from "../components/WishesForm";

export class WishesEdit extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <WishesForm
                formAction={this.data.formAction}
                backUrl={this.data.backUrl}
                elementId={this.data.elementId}
                element={this.data.element}/>,
            document.getElementById('wishesForm')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
